<script setup lang="ts">
import { useSlots } from 'vue';
import UploadUtilityOrderModal from '@utilities/components/Modals/UploadUtilityOrderModal/UploadUtilityOrderModal.vue';
import AppHeader from '~/components/navigation/appHeader/AppHeader.vue';
import { useMainStore } from '~/stores/mainStore';

const { user } = await useAuth();
const { getAccountDetails, getSiteSettings, registerWebsocketEvents } =
  useMainStore();
const slots = useSlots();
const { $sentryCaptureException } = useNuxtApp();

getAccountDetails();
getSiteSettings();

if (user && user.id) {
  registerWebsocketEvents(user.landlord.id);
} else {
  const error =
    'User was not available when MainLayout loaded: ' + JSON.stringify(user);
  $sentryCaptureException(error);
}

const hasNavSlot = computed(() => {
  return typeof slots.nav !== 'undefined';
});
</script>

<template>
  <div class="flex h-screen w-screen flex-col" data-cy="default">
    <div class="flex w-full">
      <AppHeader :show-side-bar-button="hasNavSlot" />
    </div>

    <div class="relative flex flex-1 overflow-hidden">
      <slot name="nav"></slot>
      <slot name="page"></slot>
    </div>

    <UploadUtilityOrderModal />
  </div>
</template>
